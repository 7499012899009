import React, { useMemo } from 'react';

import ContentModule from '@/components/ContentModule';
import { parseVariantSize, parseVariantTheme } from '@/components/ContentModule/variants';

import { convertToFormBlock } from '../submodules/SubModuleForm';

interface Props {
  node: ModuleGenericWithFormOnlyFragment;
  index: number;
}

export default function ModuleGenericWithFormOnly({ node, index }: Props) {
  const form = useMemo(() => convertToFormBlock(node.subModuleForm), [node.subModuleForm]);
  const image = node.image?.[0];

  const theme = parseVariantTheme(node.contentModuleVariantThemes);
  const size = parseVariantSize(node.contentModuleVariantSizes);

  if (!form || !image) return null; // Fallback
  return (
    <ContentModule
      variant="formOnly"
      slots={[]}
      form={form}
      image={image}
      reverse={index % 2 === 0}
      theme={theme}
      size={size}
    />
  );
}
