import React from 'react';
import { useToggle } from 'react-use';

import ImageOrPlaceholder from '@/components/ContentModule/common/ImageOrPlaceholder';
import { IconMail, IconMessageLanguage, IconPhone } from '@/images/icons/tabler-icons';
import { telPhone } from '@/lib/utils';

import StaffModal from '../StaffModal';

import styles from './styles.module.scss';

type Props = {
  entry: StaffFragment;
};

const StaffCard = ({ entry }: Props) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div className={styles.staffCard}>
      <div className={styles.imageContainer}>
        {!entry.toggle ? (
          <button onClick={toggleOpen}>
            <ImageOrPlaceholder image={entry.image?.[0]} className={styles.image} />
          </button>
        ) : (
          <ImageOrPlaceholder image={entry.image?.[0]} className={styles.image} />
        )}
      </div>
      <div className={styles.content}>
        {!entry.toggle && (
          <button aria-hidden="true" tabIndex={-1} className={styles.link} onClick={toggleOpen}>
            <span aria-hidden={true} />
          </button>
        )}
        <h2>{entry.title}</h2>
        {!!entry.plainText && (
          <div className={styles.groups}>
            <div className={styles.title}>{entry.plainText}</div>
          </div>
        )}
        {!!entry.email && (
          <a href={`mailto:${entry.email}`} className={styles.contact}>
            <IconMail />
            <span>{entry.email}</span>
          </a>
        )}
        {!!entry.phone && (
          <a href={`tel:${telPhone(entry.phone!)}`} className={styles.contact}>
            <IconPhone />
            <span>{entry.phone}</span>
          </a>
        )}
        {entry.languages && (
          <div className={styles.contact}>
            <IconMessageLanguage />
            {entry.languages}
          </div>
        )}
      </div>
      {isOpen && !entry.toggle && <StaffModal entry={entry} open={isOpen} setOpen={toggleOpen} />}
    </div>
  );
};

export default StaffCard;
