import React, { useMemo } from 'react';
import { inflect } from 'inflection';

import Repayments from '@/lib/Repayments';

import styles from './styles.module.scss';

interface Props {
  loanAmount: number;
  interestRate: number;
  baseFees: number;
  loanLength: number;
  frequency: keyof typeof Repayments.frequencyMap;
}

const TotalInterest = ({ loanAmount, interestRate, baseFees, loanLength, frequency }: Props) => {
  const repayment = useMemo(() => {
    return Repayments.getRepayment(loanAmount, loanLength, interestRate, baseFees, Repayments.frequencyMap[frequency]);
  }, [loanAmount, interestRate, baseFees, loanLength, frequency]);

  const totalRepayment = useMemo(() => {
    return (repayment * loanLength * 52) / Repayments.frequencyMap[frequency];
  }, [repayment, loanLength, frequency]);

  const totalInterest = useMemo(() => {
    return totalRepayment - loanAmount;
  }, [totalRepayment, loanAmount]);

  return (
    <p className={styles.totalInterest}>
      Total interest payable: <span>${Repayments.formatNumber(totalInterest)}</span>{' '}
      <span className={styles.over}>over</span>{' '}
      <span>
        {loanLength} {inflect('years', loanLength)}
      </span>
    </p>
  );
};

export default TotalInterest;
