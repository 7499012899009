import React, { useCallback } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import ChevronIcon from '@/images/icons/chevron--down.svg';

import styles from './styles.module.scss';

interface Props {
  groups: StaffGroupFragment[];
  currentGroup: string;
  onChange: (role: string) => void;
}

const GroupFilter = ({ groups, currentGroup, onChange }: Props) => {
  const handleClick = useCallback(
    (value: string) => {
      if (value !== currentGroup) {
        onChange(value);
      }
    },
    [currentGroup, onChange],
  );

  const handleRoleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      if (value !== currentGroup) {
        onChange(value);
      }
    },
    [currentGroup, onChange],
  );

  return (
    <div className={styles.roles}>
      <div data-show-only="desktop" className={styles.rolesDesktop}>
        <button
          className={cc({ [styles.role]: true, [styles.active]: currentGroup === '' })}
          onClick={() => handleClick('')}
        >
          All
        </button>
        {groups.map((r) => (
          <button
            key={`role-${r.title}`}
            className={cc({ [styles.role]: true, [styles.active]: currentGroup === r.title })}
            onClick={() => handleClick(r.title!)}
          >
            {r.title}
          </button>
        ))}
      </div>
      <div className={styles.rolesMobile}>
        <label data-hide-only="desktop">
          Team
          <div className={styles.selectWrapper}>
            <select value={currentGroup} onChange={handleRoleChange}>
              <option value="">All</option>
              {groups &&
                groups.map(
                  (r) =>
                    r.title && (
                      <option key={r.title} value={r.title}>
                        {r.title}
                      </option>
                    ),
                )}
            </select>
            <ChevronIcon />
          </div>
        </label>
      </div>
    </div>
  );
};

export default observer(GroupFilter);
