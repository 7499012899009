import React, { useMemo } from 'react';

import ContentModule from '@/components/ContentModule';
import { parseVariantSize, parseVariantTheme } from '@/components/ContentModule/variants';
import { extractItems } from '@/lib/GraphQLHelper';

import convertToSlots from '../submodules/SubModuleContent';

interface Props {
  node: ModuleGenericFragment;
}

export default function ModuleGeneric({ node }: Props) {
  const terms = useMemo(
    () => extractItems<TermsAndConditionsEntryFragment>(node.linkedTermsAndConditions),
    [node.linkedTermsAndConditions],
  );
  const slots = useMemo(() => convertToSlots(node.subModuleContent, terms), [node.subModuleContent, terms]);
  const theme = parseVariantTheme(node.contentModuleVariantThemes);
  const size = parseVariantSize(node.contentModuleVariantSizes);
  return (
    <ContentModule
      variant="noImage"
      slots={slots}
      theme={theme}
      size={size}
      noPadding={!!node.contentModuleVariantMobileNoPadding}
    />
  );
}
