import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import StaffCard from '@/components/ContentStaff/StaffCard';
import Pagination from '@/components/Pagination';

import styles from './styles.module.scss';

interface Props {
  list: StaffFragment[];
  totalItems: number;
  currentPage: number;
  entriesPerPage: number;
  onPageChange: (pageNumber: number) => void;
}

const StaffList = ({ list, totalItems, currentPage, entriesPerPage, onPageChange }: Props) => {
  const totalPages = useMemo(() => {
    return Math.ceil(list.length / entriesPerPage);
  }, [list.length, entriesPerPage]);

  const items = useMemo(() => {
    const offset = currentPage <= 1 ? 0 : (currentPage - 1) * entriesPerPage;
    return list.slice(offset, offset + entriesPerPage);
  }, [currentPage, entriesPerPage, list]);

  return (
    <div className={styles.staffsListPage}>
      <div className={styles.list}>
        {items.map((s) => (
          <StaffCard key={s.title} entry={s} />
        ))}
      </div>
      <div className={styles.pagination}>
        {totalPages > 1 && (
          <div className={styles.pagination} data-testid="staff-pagination">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              totalItems={totalItems}
              limit={entriesPerPage}
              displayAmount={4}
              onPageChange={onPageChange}
              itemType="members"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(StaffList);
