import React from 'react';

import { SSRContext } from '@/typings/SSRContext';

import ModuleCalculator from './ModuleCalculator';
import ModuleCarGrid from './ModuleCarGrid';
import ModuleCarousel from './ModuleCarousel';
import ModuleContactDetails from './ModuleContactDetails';
import { ModuleDynamicContentList } from './ModuleDynamicContentList';
import ModuleEntryGrid from './ModuleEntryGrid';
import ModuleFaq from './ModuleFaq';
import ModuleGeneric from './ModuleGeneric';
import ModuleGenericWithForm from './ModuleGenericWithForm';
import ModuleGenericWithFormOnly from './ModuleGenericWithFormOnly';
import ModuleGenericWithImage from './ModuleGenericWithImage';
import ModuleGenericWithVideo from './ModuleGenericWithVideo';
import ModuleMostViewedCars from './ModuleMostViewedCars';
import ModuleStaff from './ModuleStaff';
import ModuleTestimonial from './ModuleTestimonial';

type ModuleNode =
  | ModuleCalculatorFragment
  | ModuleGenericWithFormFragment
  | ModuleGenericWithFormOnlyFragment
  | ModuleGenericWithImageFragment
  | ModuleGenericWithVideoFragment
  | ModuleGenericFragment
  | ModuleCarouselFragment
  | ModuleFaqFragment
  | ModuleMostViewedCarsFragment
  | ModuleTestimonialFragment
  | ModuleCarGridFragment
  | ModuleDynamicContentListFragment
  | ModuleEntryGridFragment
  | ModuleStaffFragment
  | ModuleContactDetailsFragment;

interface Props {
  node: ModuleNode;
  index: number;
}

export default function Module({ node, index }: Props) {
  switch (node.__typename) {
    case 'contentBuilder_moduleCalculator_BlockType':
      return <ModuleCalculator node={node} />;
    case 'contentBuilder_moduleGeneric_BlockType':
      return <ModuleGeneric node={node} />;
    case 'contentBuilder_moduleGenericWithForm_BlockType':
      return <ModuleGenericWithForm node={node} index={index} />;
    case 'contentBuilder_moduleGenericWithFormOnly_BlockType':
      return <ModuleGenericWithFormOnly node={node} index={index} />;
    case 'contentBuilder_moduleGenericWithImage_BlockType':
      return <ModuleGenericWithImage node={node} index={index} />;
    case 'contentBuilder_moduleGenericWithVideo_BlockType':
      return <ModuleGenericWithVideo node={node} index={index} />;
    case 'contentBuilder_moduleCarousel_BlockType':
      return <ModuleCarousel node={node} />;
    case 'contentBuilder_moduleFaq_BlockType':
      return <ModuleFaq node={node} />;
    case 'contentBuilder_moduleMostViewedCars_BlockType':
      return <ModuleMostViewedCars node={node} />;
    case 'contentBuilder_moduleTestimonial_BlockType':
      return <ModuleTestimonial node={node} />;
    case 'contentBuilder_moduleCarGrid_BlockType':
      return <ModuleCarGrid node={node} />;
    case 'contentBuilder_moduleDynamicContentList_BlockType':
      return <ModuleDynamicContentList node={node} />;
    case 'contentBuilder_moduleEntryGrid_BlockType':
      return <ModuleEntryGrid node={node} />;
    case 'contentBuilder_moduleStaff_BlockType':
      return <ModuleStaff node={node} />;
    case 'contentBuilder_moduleContactDetails_BlockType':
      return <ModuleContactDetails node={node} />;
    /* istanbul ignore next */
    default:
      return null;
  }
}

export async function prefetchModule(context: SSRContext, node: ModuleNode) {
  switch (node.__typename) {
    case 'contentBuilder_moduleMostViewedCars_BlockType':
      await ModuleMostViewedCars.prefetch(context, node);
      break;
    case 'contentBuilder_moduleCarGrid_BlockType':
      await ModuleCarGrid.prefetch(context, node);
      break;
    case 'contentBuilder_moduleDynamicContentList_BlockType':
      await ModuleDynamicContentList.prefetch(context, node);
      break;
    case 'contentBuilder_moduleGeneric_BlockType':
    case 'contentBuilder_moduleGenericWithForm_BlockType':
    case 'contentBuilder_moduleGenericWithFormOnly_BlockType':
    case 'contentBuilder_moduleGenericWithImage_BlockType':
    case 'contentBuilder_moduleGenericWithVideo_BlockType':
    case 'contentBuilder_moduleCarousel_BlockType':
    case 'contentBuilder_moduleFaq_BlockType':
    case 'contentBuilder_moduleTestimonial_BlockType':
    case 'contentBuilder_moduleEntryGrid_BlockType':
    case 'contentBuilder_moduleContactDetails_BlockType':
      break;
    case 'contentBuilder_moduleStaff_BlockType':
      await ModuleStaff.prefetch(context, node);
      break;
    default:
      break;
  }
}

export type { ModuleNode };
