import { TestimonialItem } from '@/components/ContentTestimonial';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode = SubModuleTestimonialItemFragment;

const DEFAULT_RATING = 4;

export default function covertToTestimonialItems(
  node?: Nullable<SubModuleTestimonialFragment>,
): Array<TestimonialItem> {
  return (
    extractItems<ChildNode>(node?.nodes)
      ?.filter(
        (node): node is SubModuleTestimonialItemFragment =>
          node.__typename === 'subModuleTestimonial_testimonialItem_BlockType',
      )
      .map((node) => ({
        name: node.contactName || '',
        rating: node.rating === undefined || node.rating === null ? DEFAULT_RATING : node.rating,
        paragraph: node.textarea || '',
      })) || []
  );
}
