import React, { useMemo } from 'react';
import { inflect } from 'inflection';

import Repayments from '@/lib/Repayments';

import styles from './styles.module.scss';

interface Props {
  loanAmount: number;
  interestRate: number;
  baseFees: number;
  loanLength: number;
  frequency: keyof typeof Repayments.frequencyMap;
  totalRepayment: number;
}

const Popups = ({ loanAmount, interestRate, baseFees, loanLength, frequency, totalRepayment }: Props) => {
  const repaymentArray = useMemo(() => {
    const maxAmount = Repayments.getMaxAmountValueOnChart(totalRepayment);
    const repaymentPercentMax = (totalRepayment / maxAmount) * 100;

    const values: number[] = [];
    for (let i = 1; i <= loanLength - 1; i++) {
      values.push(100 - (repaymentPercentMax - (repaymentPercentMax / loanLength) * i));
    }
    return values;
  }, [totalRepayment, loanLength]);

  const principalValues = useMemo(() => {
    return Repayments.getPrincipalPerYear(
      loanAmount,
      loanLength,
      interestRate,
      baseFees,
      Repayments.frequencyMap[frequency],
    );
  }, [loanAmount, interestRate, baseFees, loanLength, frequency]);

  return (
    <>
      {repaymentArray.map((repayment, idx) => (
        <div
          key={`repayment-point-${idx}`}
          className={styles.repaymentPerYear}
          style={{
            left: `${((idx + 0.5) / loanLength) * 100}%`,
            width: `${(1 / loanLength) * 100}%`,
          }}
        >
          <div className={styles.popup} style={{ top: `calc(${repayment}% - 120px)` }}>
            {principalValues?.[idx] && (
              <>
                <p>Principal remaining</p>
                <p className={styles.bold}>${Repayments.formatNumber(principalValues[idx])}</p>
              </>
            )}
            <p>Years remaining</p>
            <p className={styles.bold}>
              {loanLength - idx - 1} {inflect('year', loanLength - idx - 1, 'year', 'years')}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Popups;
