import React from 'react';

import { StaticClient } from '@/lib/MapboxClient';

import styles from './styles.module.scss';

interface Props {
  lat?: Nullable<number | string>;
  lng?: Nullable<number | string>;
  width?: number;
  height?: number;
  zoom?: number;
  pitch?: number;
  address?: string;
  alt?: string;
  lazy?: boolean;
}

const Map = ({ lat, lng, width, height, zoom, pitch, address, alt, lazy = true }: Props) => {
  if (lat && lng) {
    lat = Number.parseFloat(lat?.toString());
    lng = Number.parseFloat(lng?.toString());
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address || `${lat},${lng}`)}`;

    const request = StaticClient()?.getStaticImage({
      ownerId: 'mapbox',
      styleId: 'streets-v12',
      width: width || 300,
      height: height || 200,
      highRes: true,
      position: {
        coordinates: [lng, lat],
        zoom: zoom || 13,
        pitch: pitch || 60,
      },
      overlays: [
        {
          marker: {
            coordinates: [lng, lat],
            size: 'large',
            color: 'B43347',
          } as any, // TODO: fix typing at https://github.com/DefinitelyTyp/DedefinitelyTyped/tree/master/types/mapbox__mapbox-sdk
        },
      ],
    });
    if (request)
      return (
        <a href={url} target="_blank" rel="noreferrer noopener" className={styles.map}>
          <img loading={lazy ? 'lazy' : undefined} src={request.url()} alt={alt || ''} />
        </a>
      );
  }

  return null;
};

export default Map;
