import React, { useMemo } from 'react';

import styles from './styles.module.scss';

interface Props {
  loanLength: number;
}

const YearNumber = ({ loanLength }: Props) => {
  // the final year is not counted
  const yearArray = useMemo(() => {
    return Array.from({ length: loanLength - 1 }, (_, i) => i + 1);
  }, [loanLength]);

  return (
    <>
      <div className={styles.year}>Year 0</div>
      {yearArray.map((y) => (
        <div key={`year-${y}`} className={styles.year} style={{ left: `${(y / loanLength) * 100}%` }}>
          Year {y}
        </div>
      ))}
      {yearArray.map((idx) => (
        <div key={`divider-${idx}`} className={styles.yearDivider} style={{ left: `${(idx / loanLength) * 100}%` }} />
      ))}
      <div className={styles.year} style={{ left: '100%' }}>
        Year {loanLength}
      </div>
    </>
  );
};

export default YearNumber;
