import { FaqItem } from '@/components/ContentFaq';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode = SubModuleFaqItemFragment;

export function convertToFaqItems(node?: Nullable<SubModuleFaqFragment>): Array<FaqItem> {
  return (
    extractItems<ChildNode>(node?.nodes)
      ?.filter((node): node is SubModuleFaqItemFragment => node.__typename === 'subModuleFaq_faqItem_BlockType')
      .map((node) => ({
        heading: `<h3>${node.customTitle}</h3>`,
        paragraph: node.paragraph?.renderHtml || '',
      })) || []
  );
}
