import React from 'react';
import cc from 'classcat';

import Map from '@/components/Map';
import useStore from '@/hooks/useStore';
import { IconClock, IconMail, IconMapPin, IconPhoneCall } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

interface Props {
  node: ModuleContactDetailsFragment;
}

export default function ModuleContactDetails({ node }: Props) {
  const { siteName } = useStore('globals');

  return (
    <div className={styles.contact}>
      <div>
        <h3>Contact details</h3>
        <div className={styles.columns}>
          <div className={styles.phone}>
            <div>
              <IconPhoneCall />
              <span className={styles.subHeading}>Phone</span>
            </div>
            <div>
              {!!node.phone ? (
                <div className={styles.contactParagraph} dangerouslySetInnerHTML={{ __html: node.phone }} />
              ) : (
                <div className={styles.contactParagraph}>(TBC)</div>
              )}
            </div>
          </div>
          <div className={styles.email}>
            <div>
              <IconMail />
              <span className={styles.subHeading}>Email</span>
            </div>
            <div>
              {!!node.email ? (
                <a href={`mailto:${node.email}`}>{node.email}</a>
              ) : (
                <div className={styles.contactParagraph}>(TBC)</div>
              )}
            </div>
          </div>
          {node.address && (
            <div className={styles.address}>
              <div>
                <IconMapPin />
                <span className={styles.subHeading}>Visit</span>
              </div>
              <div>
                <div>
                  <p>{node.address.address}</p>
                </div>
              </div>
            </div>
          )}
          {node.body && (
            <div className={styles.hours}>
              <div>
                <IconClock />
                <span className={styles.subHeading}>Visit Opening hours</span>
              </div>
              <div>
                <div dangerouslySetInnerHTML={{ __html: node.body }} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={cc([styles.map, styles.noPadding])}>
        <Map
          address={`${siteName}, ${node.address?.address}`}
          lat={node.address?.lat}
          lng={node.address?.lng}
          width={730}
          height={570}
          pitch={0.1}
          zoom={12}
          alt={`Map of ${siteName}`}
        />
      </div>
    </div>
  );
}
