import React, { useMemo } from 'react';

import ContentModule from '@/components/ContentModule';
import SlotDynamicButton, { slotDynamicButtonVariants } from '@/components/ContentModule/slots/DynamicButton';
import {
  parseVariantOrder,
  parseVariantSize,
  parseVariantTheme,
  parseVideoVariant,
} from '@/components/ContentModule/variants';
import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import convertToSlots from '../submodules/SubModuleContent';

interface Props {
  node: ModuleGenericWithVideoFragment;
  index: number;
}

export default function ModuleGenericWithVideo({ node, index }: Props) {
  const terms = useMemo(
    () => extractItems<TermsAndConditionsEntryFragment>(node.linkedTermsAndConditions),
    [node.linkedTermsAndConditions],
  );
  const slots = useMemo(() => convertToSlots(node.subModuleContent, terms), [node.subModuleContent, terms]);

  const variant = parseVideoVariant(node.contentModuleVideoVariants);
  const theme = parseVariantTheme(node.contentModuleVariantThemes);
  const size = parseVariantSize(node.contentModuleVariantSizes);
  const order = parseVariantOrder(node.contentModuleVariantOrder);
  const autoplayVideo = !!node.contentModuleVideoAutoplay;
  const videoUrl = node.videoAsset?.[0]?.webp || node.videoUrl || '';

  return (
    <ContentModule
      variant={variant}
      slots={slots}
      videoUrl={videoUrl}
      reverse={index % 2 === 0}
      theme={theme}
      size={size}
      order={order}
      autoplayVideo={autoplayVideo}
    />
  );
}

ModuleGenericWithVideo.prefetch = async (context: SSRContext, node: ModuleGenericWithVideoFragment) => {
  // prefetch dynamicButton
  const nodes = node.subModuleContent?.nodes || [];
  for (const node of nodes) {
    if (
      node &&
      '__typename' in node &&
      node?.__typename === 'subModuleContent_dynamicButton_BlockType' &&
      node.contentModuleDynamicButtonVariants
    ) {
      await SlotDynamicButton.prefetch(context, node.contentModuleDynamicButtonVariants as slotDynamicButtonVariants);
    }
  }

  return;
};
