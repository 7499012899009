import { extractItems } from '@/lib/GraphQLHelper';

export type ChildNode = SubModuleCalculatorButtonFragment;

export function convertToButtons(
  node?: Nullable<SubModuleCalculatorFragment>,
): Array<SubModuleCalculatorButtonFragment> {
  return (
    extractItems<ChildNode>(node?.nodes)?.filter(
      (node): node is SubModuleCalculatorButtonFragment => node.__typename === 'subModuleCalculator_button_BlockType',
    ) || []
  );
}
