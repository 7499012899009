import React, { useEffect, useState } from 'react';
import { Client, useClient } from 'urql';

import locationFilterLinksQuery from '@/graphql/queries/locationFilterLinks.graphql';
import useStore from '@/hooks/useStore';
import { extractItems } from '@/lib/GraphQLHelper';
import Log from '@/lib/Log';
import { stockSlugify } from '@/lib/slugify';
import { getBodyStyleFilter, getMakeFilter } from '@/lib/StockFilterHelper';
import StockViewCount from '@/lib/StockViewCount';
import { textCapitalize } from '@/lib/TextCapitalize';
import { SSRContext } from '@/typings/SSRContext';

import styles from './styles.module.scss';

interface Props {
  node: ModuleDynamicContentListFragment;
}

export const DYMAMIC_CONTENT_LIST_VARIANTS = ['make', 'bodyStyle', 'location'] as const;
export type DynamicContentListVariants = (typeof DYMAMIC_CONTENT_LIST_VARIANTS)[number];

export const ModuleDynamicContentList = ({ node }: Props) => {
  const urqlClient = useClient();
  const { site } = useStore('globals');
  const dealershipsmap = useStore('dealershipsMap');
  const [listData, setListData] = useState<ListData>([]);
  const listVariant = parseListVariant(node.contentModuleDynamicContentListVariants);

  useEffect(() => {
    const asyncFn = async () => {
      if (listVariant) {
        const filterString = (await StockViewCount.getDealerIdFilters(urqlClient, site)) ?? '';
        setListData(await getVariant(urqlClient, site, filterString, listVariant));
      }
    };

    asyncFn();
  }, [dealershipsmap.dealershipsArray, site, urqlClient, listVariant]);

  if (!listData.length) return null;

  return (
    <div className={styles.contentList}>
      <h2>{node.plainText ?? ModuleDynamicContentList.defaultTilte(listVariant)}</h2>
      <div className={styles.list}>
        {listData.map((data) => (
          <div key={data.title}>
            <a href={data.link}>{data.title}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

ModuleDynamicContentList.defaultTilte = (variant: DynamicContentListVariants) => {
  switch (variant) {
    case 'make':
      return 'Browse by make';
    case 'bodyStyle':
      return 'Browse by type';
    case 'location':
      return 'Browse by partners';
    default:
      return '';
  }
};

ModuleDynamicContentList.prefetch = async (
  { urqlClient, nextCtx: { locale } }: SSRContext,
  node: ModuleDynamicContentListFragment,
) => {
  const listVariant = parseListVariant(node.contentModuleDynamicContentListVariants);
  if (urqlClient && listVariant) {
    const filterString = (await StockViewCount.getDealerIdFilters(urqlClient, locale!)) ?? '';
    await getVariant(urqlClient, locale!, filterString, listVariant);
  }
};

const isListVariant = (value: string): value is DynamicContentListVariants =>
  (DYMAMIC_CONTENT_LIST_VARIANTS as readonly string[]).includes(value);
const parseListVariant = (value?: Nullable<string>): DynamicContentListVariants =>
  value && isListVariant(value) ? value : 'make';

// Variants
type ListData = Array<{
  title: string;
  link: string;
}>;

const getVariant = async (
  urqlClient: Client,
  locale: string,
  filterString: string,
  variant: DynamicContentListVariants,
): Promise<ListData> => {
  switch (variant) {
    case 'make':
      const makeFilter = await getMakeFilter(urqlClient, filterString);
      return makeFilter.map((f) => ({ title: textCapitalize(f.make), link: `cars/${stockSlugify(f.make)}` }));

    case 'bodyStyle':
      const bodyFilter = await getBodyStyleFilter(urqlClient, filterString);
      return bodyFilter.map((f) => ({ title: textCapitalize(f), link: `cars/body/${stockSlugify(f)}` }));

    case 'location':
      const { data, operation, error } = await urqlClient
        .query<LocationFilterLinksQuery>(locationFilterLinksQuery, {
          site: locale,
        })
        .toPromise();
      if (error) {
        Log.error(`ModuleDynamicContentList: LocationFiltersLinkQuery failed`, {
          operation,
          error,
        });
        return [];
      }
      const locationFilters = extractItems<LocationFilterLinkFragment>(data?.entries);
      return (
        locationFilters?.filter((f) => f.title && f.uri).map((f) => ({ title: f.title!, link: `/${f.uri!}` })) ?? []
      );

    default:
      break;
  }

  return [];
};
