import React, { useEffect, useMemo, useState } from 'react';
import { Client, useClient } from 'urql';

import PromotionTile from '@/components/SearchDirectory/components/PromotionTile';
import VehicleCard from '@/components/VehicleCard';
import useStore from '@/hooks/useStore';
import { getDealerIdFromInventorySource } from '@/lib/DealershipHelper';
import { extractItems } from '@/lib/GraphQLHelper';
import Log from '@/lib/Log';
import stockQuery from '@/lib/Stock/stock.graphql';
import StockViewCount from '@/lib/StockViewCount';
import { Filter } from '@/models';
import { SSRContext } from '@/typings/SSRContext';

import styles from './styles.module.scss';

interface Props {
  node: ModuleCarGridFragment;
}

export default function ModuleCarGrid({ node }: Props) {
  const urqlClient = useClient();
  const { site } = useStore('globals');
  const dealershipsmap = useStore('dealershipsMap');
  const [vehicles, setVehicles] = useState<StockItem[]>([]);

  const promoTile = useMemo(
    () => extractItems<PromotionTilesFragment>(node.promotionTileEntry)?.[0],
    [node.promotionTileEntry],
  );

  const filters = useMemo(() => {
    // if childFilter is set, use that instead of dealershipmap
    //  childfilter dealerID can only ever be a subset of dealshipmap dealerIDs
    const inventorySource = extractItems<LocationFragment>(node.childLocation)?.[0]?.inventorySource ?? [];
    const dealerId = getDealerIdFromInventorySource(inventorySource);
    const childFilter: Filter<string> | undefined = dealerId ? new Filter('dealerID', dealerId) : undefined;

    if (childFilter) return Filter.toQueryString([childFilter]);

    return Filter.toQueryString(
      dealershipsmap.dealershipsArray.map((d) => new Filter('dealerID', d.dealeridentifier!)),
    );
  }, [dealershipsmap.dealershipsArray, node.childLocation]);

  useEffect(() => {
    const asyncFn = async () => {
      setVehicles(
        (await getVehicles(urqlClient, site, filters, node.contentModuleCarGridSortOptions ?? undefined)) ?? [],
      );
    };

    asyncFn();
  }, [filters, site, node.contentModuleCarGridSortOptions, urqlClient]);

  if (vehicles.length < ModuleCarGrid.vehicleAmount) return null;

  return (
    <div className={styles.carGrid}>
      <p className={styles.heading}>{node.plainText ? node.plainText : ModuleCarGrid.heading}</p>
      <div className={styles.vehicleGrid}>
        {vehicles.map((v) => (
          <VehicleCard key={v.stockNo} vehicle={v} />
        ))}
        {promoTile && <PromotionTile tile={promoTile} smallMobile />}
      </div>
    </div>
  );
}

const getVehicles = async (urqlClient: Client, locale: string, filterString: string, sort?: string) => {
  const { data, operation, error } = await urqlClient
    .query<StockQuery>(stockQuery, {
      limit: ModuleCarGrid.vehicleAmount,
      sort: sort,
      site: locale,
      filters: filterString,
      weight: '',
    })
    .toPromise();

  if (error) {
    Log.error('ModuleCarGrid: getVehciles() query failed', {
      error,
      operation,
    });
    return [];
  }

  if (data && data.stockList) {
    return extractItems<StockItem>(data.stockList.stock);
  }

  return [];
};

ModuleCarGrid.heading = 'Shop our range of vehicles';
ModuleCarGrid.vehicleAmount = 5;

ModuleCarGrid.prefetch = async (context: SSRContext, node: ModuleCarGridFragment) => {
  const {
    urqlClient,
    nextCtx: { locale },
  } = context;

  const inventorySource = extractItems<LocationFragment>(node.childLocation)?.[0]?.inventorySource ?? [];
  const dealerId = getDealerIdFromInventorySource(inventorySource);
  const childFilter: Filter<string> | undefined = dealerId ? new Filter('dealerID', dealerId) : undefined;

  let filterStr = '';
  if (childFilter) {
    filterStr = Filter.toQueryString([childFilter]);
  } else {
    filterStr = (await StockViewCount.getDealerIdFilters(context.urqlClient, locale!)) ?? '';
  }

  await getVehicles(urqlClient, locale!, filterStr, node.contentModuleCarGridSortOptions ?? undefined);
};
