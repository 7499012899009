import React from 'react';
import Carousel from 'react-multi-carousel';

import ChevronBackIcon from '@/images/icons/chevron--back.svg';

import ContentTestimonialItem from './ContentTestimonialItem';

import styles from './styles.module.scss';

interface Props {
  heading: string;
  items: Array<TestimonialItem>;
}

export interface TestimonialItem {
  rating: number;
  name: string;
  paragraph: string;
}

export default function ContentTestimonial({ heading, items }: Props) {
  return (
    <div className={styles.contentTestimonial}>
      <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }} />
      <div className={styles.carouselWrapper}>
        <Carousel
          containerClass={styles.slider}
          itemClass={styles.item}
          swipeable
          draggable
          infinite
          ssr
          showDots
          renderDotsOutside
          responsive={{ all: { breakpoint: { max: 10000, min: 0 }, items: 1 } }}
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={<CustomButtonGroup />}
        >
          {items.map((item) => (
            <ContentTestimonialItem {...item} key={item.name} />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export function CustomButtonGroup({ previous, next }: Partial<Carousel>) {
  return (
    <div className={styles.buttonGroup}>
      <button data-testid="prev" onClick={() => previous!(0)} aria-label="Previous item">
        <ChevronBackIcon height={16} width={8} />
      </button>
      <button data-testid="next" onClick={() => next!(0)} aria-label="Next item">
        <ChevronBackIcon height={16} width={8} />
      </button>
    </div>
  );
}
