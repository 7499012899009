import React, { useMemo } from 'react';

import ContentModule from '@/components/ContentModule';
import SlotDynamicButton, { slotDynamicButtonVariants } from '@/components/ContentModule/slots/DynamicButton';
import {
  parseImageVariant,
  parseVariantOrder,
  parseVariantSize,
  parseVariantTheme,
} from '@/components/ContentModule/variants';
import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import convertToSlots from '../submodules/SubModuleContent';

interface Props {
  node: ModuleGenericWithImageFragment;
  index: number;
}

export default function ModuleGenericWithImage({ node, index }: Props) {
  const terms = useMemo(
    () => extractItems<TermsAndConditionsEntryFragment>(node.linkedTermsAndConditions),
    [node.linkedTermsAndConditions],
  );
  const slots = useMemo(() => convertToSlots(node.subModuleContent, terms), [node.subModuleContent, terms]);

  const variant = parseImageVariant(node.contentModuleImageVariants);
  const theme = parseVariantTheme(node.contentModuleVariantThemes);
  const size = parseVariantSize(node.contentModuleVariantSizes);
  const order = parseVariantOrder(node.contentModuleVariantOrder);
  const overflowStyle = !!node.contentModuleImageOverflowStyle;
  return (
    <ContentModule
      variant={variant}
      slots={slots}
      image={node.image?.[0]}
      reverse={index % 2 === 0}
      theme={theme}
      size={size}
      order={order}
      overflowStyle={overflowStyle}
    />
  );
}

ModuleGenericWithImage.prefetch = async (context: SSRContext, node: ModuleGenericWithImageFragment) => {
  // prefetch dynamicButton
  const nodes = node.subModuleContent?.nodes || [];
  for (const node of nodes) {
    if (
      node &&
      '__typename' in node &&
      node?.__typename === 'subModuleContent_dynamicButton_BlockType' &&
      node.contentModuleDynamicButtonVariants
    ) {
      await SlotDynamicButton.prefetch(context, node.contentModuleDynamicButtonVariants as slotDynamicButtonVariants);
    }
  }

  return;
};
