import React from 'react';

import ContentFaq from '@/components/ContentFaq';

import { convertToFaqItems } from '../submodules/SubModuleFaq';

interface Props {
  node: ModuleFaqFragment;
}

export default function ModuleFaq({ node }: Props) {
  const items = convertToFaqItems(node.subModuleFaq);
  return <ContentFaq heading={node.subModuleFaq?.renderHtml || ''} items={items} />;
}
