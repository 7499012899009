import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useClient } from 'urql';

import PromotionTile from '@/components/SearchDirectory/components/PromotionTile';
import VehicleCard from '@/components/VehicleCard';
import useStore from '@/hooks/useStore';
import { extractItems } from '@/lib/GraphQLHelper';
import StockViewCount from '@/lib/StockViewCount';
import { SSRContext } from '@/typings/SSRContext';

import styles from './styles.module.scss';

interface Props {
  node: ModuleMostViewedCarsFragment;
}

export default function ModuleMostViewedCars({ node }: Props) {
  const urqlClient = useClient();
  const router = useRouter();
  const dealershipsMapStore = useStore('dealershipsMap');
  const [vehicles, setVehicles] = useState<StockItem[]>([]);

  const promoTile = useMemo(
    () => extractItems<PromotionTilesFragment>(node.promotionTileEntry)?.[0],
    [node.promotionTileEntry],
  );

  useEffect(() => {
    const getVehicles = async () => {
      setVehicles(
        await StockViewCount.getTodaysVehicles(urqlClient, ModuleMostViewedCars.vehicleAmount, router.locale ?? ''),
      );
    };
    getVehicles();
  }, [urqlClient, dealershipsMapStore, router.locale]);

  if (vehicles.length < ModuleMostViewedCars.vehicleAmount) return null;

  return (
    <div className={styles.mostViewedCars}>
      <p className={styles.heading}>{ModuleMostViewedCars.heading}</p>
      <div className={styles.vehicleGrid}>
        {vehicles.map((v) => (
          <VehicleCard key={v.stockNo} vehicle={v} />
        ))}
        {promoTile && <PromotionTile tile={promoTile} smallMobile />}
      </div>
    </div>
  );
}

ModuleMostViewedCars.heading = 'Most viewed cars today';
ModuleMostViewedCars.vehicleAmount = 5;

ModuleMostViewedCars.prefetch = async (
  { urqlClient, stores: { globals } }: SSRContext,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _node: ModuleMostViewedCarsFragment,
) => {
  await StockViewCount.getTodaysVehicles(urqlClient, 5, globals.site);
  return;
};
