export function textCapitalize(text: Maybe<string> | undefined) {
  if (!text) {
    return '';
  }

  switch (text.toLowerCase()) {
    case 'mercedes-benz':
      return 'Mercedes Benz';
    case 'mercedes-amg':
      return 'Mercedes AMG';
    case 'harley-davidson':
      return 'Harley Davidson';
    case 'suv':
    case 'bmw':
    case 'mg':
    case 'fpv':
    case 'ldv':
    case 'mdc':
      return text.toUpperCase();
    // no default
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
