import React, { useEffect, useMemo, useState } from 'react';
import { Client, useClient } from 'urql';

import ContentStaff from '@/components/ContentStaff';
import staffQuery from '@/graphql/queries/staff.graphql';
import useStore from '@/hooks/useStore';
import { extractItems } from '@/lib/GraphQLHelper';
import Log from '@/lib/Log';
import { SSRContext } from '@/typings/SSRContext';

interface Props {
  node: ModuleStaffFragment;
}

interface StaffResponse {
  staffs: StaffFragment[];
  groups: StaffGroupFragment[];
}

export default function ModuleStaff({ node }: Props) {
  const urqlClient = useClient();
  const { site } = useStore('globals');
  const [staffs, setStaffs] = useState<StaffFragment[]>([]);
  const [groups, setGroups] = useState<StaffGroupFragment[]>([]);

  const locations = useMemo(() => {
    return node.staffLocation
      ?.filter((l): l is StaffLocationFragment => !!(l && '__typename' in l))
      .map((location: StaffLocationFragment) => location.id!);
  }, [node.staffLocation]);

  useEffect(() => {
    const asyncFn = async () => {
      const response = await getStaffs(urqlClient, site, locations);
      if (response) {
        setStaffs(response.staffs);
        setGroups(response.groups);
      }
    };

    asyncFn();
  }, [locations, site, urqlClient]);

  if (!staffs.length) return null;

  return <ContentStaff staffs={staffs} groups={groups} />;
}

const getStaffs = async (urqlClient: Client, locale: string, locations: string[]) => {
  const { data, operation, error } = await urqlClient
    .query<StaffListQuery>(staffQuery, {
      site: locale,
      locations,
    })
    .toPromise();

  if (error) {
    Log.error('ModuleStaff: getStaffListQuery() query failed', {
      error,
      operation,
    });
    return null;
  }

  if (data && data.entries && data.groups) {
    const staffs = extractItems<StaffFragment>(data.entries) ?? [];
    const groups = extractItems<StaffGroupFragment>(data.groups) ?? [];
    return {
      staffs,
      groups,
    } as StaffResponse;
  }

  return null;
};

ModuleStaff.prefetch = async (context: SSRContext, node: ModuleStaffFragment) => {
  const {
    urqlClient,
    nextCtx: { locale },
  } = context;

  const locations = node.staffLocation
    ?.filter((l): l is StaffLocationFragment => !!(l && '__typename' in l))
    .map((location: StaffLocationFragment) => location.id!);
  await getStaffs(urqlClient, locale!, locations);
};
