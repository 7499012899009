import { flatten, uniqBy } from 'lodash';

import { extractItems } from '@/lib/GraphQLHelper';
import { injectTerms } from '@/lib/TermsHelper';

import { ModuleNode } from './modules';
import { ChildNode as CarouselChildNode } from './modules/submodules/SubModuleCarousel';
import { ChildNode } from './modules/submodules/SubModuleContent';

export interface InjectTermsAndConditionsOptions {
  prependedAdditionalTerms?: Array<TermsAndConditionsEntryFragment>;
  appendedAdditionTerms?: Array<TermsAndConditionsEntryFragment>;
}

/**
 * Injects terms and conditions into content module paragraphs.
 *
 * We do this by traversing the content builder try and finding anywhere with '**'
 * and replacing this with the correct terms it relates to.
 */
export function injectTermsAndConditions(items: Array<ModuleNode>, options?: InjectTermsAndConditionsOptions) {
  const nodes: Array<ModuleNode> = [];

  const prependedAdditionalTerms = options?.prependedAdditionalTerms || [];
  const appendedAdditionTerms = options?.appendedAdditionTerms || [];
  const allTerms = uniqBy(
    flatten([
      ...prependedAdditionalTerms,
      ...items
        .filter(
          (
            item,
          ): item is
            | ModuleGenericFragment
            | ModuleGenericWithFormFragment
            | ModuleGenericWithImageFragment
            | ModuleGenericWithVideoFragment
            | ModuleCarouselFragment =>
            [
              'contentBuilder_moduleGenericWithForm_BlockType',
              'contentBuilder_moduleGenericWithImage_BlockType',
              'contentBuilder_moduleGenericWithVideo_BlockType',
              'contentBuilder_moduleGeneric_BlockType',
              'contentBuilder_moduleCarousel_BlockType',
            ].includes(item.__typename),
        )
        .map((item) => extractItems<TermsAndConditionsEntryFragment>(item.linkedTermsAndConditions) || []),
      ...appendedAdditionTerms,
    ]),
    'slug',
  );

  const allTermsMap = new Map<string, number>(
    allTerms.reduce((prev: Array<[string, number]>, curr, i) => [...prev, [curr.slug!, i + 1]], []),
  );

  for (const item of items) {
    switch (item.__typename) {
      case 'contentBuilder_moduleGenericWithImage_BlockType':
      case 'contentBuilder_moduleGenericWithForm_BlockType':
      case 'contentBuilder_moduleGenericWithVideo_BlockType':
      case 'contentBuilder_moduleGeneric_BlockType':
        if (!item.subModuleContent) {
          nodes.push(item);
          break;
        }

        let moduleTermsCount = 0;
        const terms = extractItems<TermsAndConditionsEntryFragment>(item.linkedTermsAndConditions) || [];

        const injectModuleTerms = (html: Nullable<string> | undefined) => {
          if (!html?.includes('**')) return html;
          while (html.includes('**')) {
            html = injectTerms(html, allTermsMap, terms?.[moduleTermsCount++]?.slug);
          }
          return html;
        };

        nodes.push({
          ...item,
          subModuleContent: {
            ...item.subModuleContent,
            nodes: extractItems<ChildNode>(item.subModuleContent?.nodes)?.map((node) =>
              node.__typename === 'VizyNode_Paragraph' ? { ...node, html: injectModuleTerms(node.html) } : node,
            ),
          },
        });
        break;
      case 'contentBuilder_moduleCarousel_BlockType':
        if (!item.subModuleCarousel) {
          nodes.push(item);
          break;
        }

        let carouselTermsCount = 0;
        const carouselTerms = extractItems<TermsAndConditionsEntryFragment>(item.linkedTermsAndConditions) || [];

        const injectCarouselTerms = (html: Nullable<string> | undefined) => {
          if (!html?.includes('**')) return html;
          while (html.includes('**')) {
            html = injectTerms(html, allTermsMap, carouselTerms?.[carouselTermsCount++]?.slug);
          }
          return html;
        };

        nodes.push({
          ...item,
          subModuleCarousel: {
            ...item.subModuleCarousel,
            nodes: extractItems<CarouselChildNode>(item.subModuleCarousel?.nodes)?.map((node) => {
              if (node.__typename === 'VizyNode_Paragraph') {
                return { ...node, html: injectCarouselTerms(node.html) };
              }

              if (node.__typename === 'subModuleCarousel_carouselItem_BlockType' && node.paragraph) {
                return {
                  ...node,
                  paragraph: {
                    ...node.paragraph,
                    renderHtml: injectCarouselTerms(node.paragraph.renderHtml),
                  },
                };
              }
              return node;
            }),
          },
        });
        break;
      default:
        nodes.push(item);
        break;
    }
  }

  return [nodes, allTerms] as const;
}
