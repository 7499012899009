import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import Head from 'next/head';
import { FAQPage } from 'schema-dts';

import ContentFaqItem from './ContentFaqItem';

import styles from './styles.module.scss';

interface Props {
  heading: string;
  items: Array<FaqItem>;
  useSchema?: boolean;
}

export interface FaqItem {
  heading: string;
  paragraph: string;
}

export default function ContentFaq({ heading, items, useSchema = false }: Props) {
  return (
    <>
      {useSchema && (
        <Head>
          <script
            {...jsonLdScriptProps<FAQPage>({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: items.map((f) => ({
                '@type': 'Question',
                name: f.heading,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: f.paragraph,
                },
              })),
            })}
          />
        </Head>
      )}
      <div className={styles.contentFaq}>
        <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }} />
        <div className={styles.items}>
          {items.map((item) => (
            <ContentFaqItem key={item.heading} heading={item.heading} paragraph={item.paragraph} />
          ))}
        </div>
      </div>
    </>
  );
}
