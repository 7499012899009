import React, { useMemo } from 'react';

import ContentModule from '@/components/ContentModule';
import { parseFormVariant, parseVariantSize, parseVariantTheme } from '@/components/ContentModule/variants';
import { extractItems } from '@/lib/GraphQLHelper';

import convertToSlots from '../submodules/SubModuleContent';
import { convertToFormBlock } from '../submodules/SubModuleForm';

interface Props {
  node: ModuleGenericWithFormFragment;
  index: number;
}

export default function ModuleGenericWithForm({ node, index }: Props) {
  const terms = useMemo(
    () => extractItems<TermsAndConditionsEntryFragment>(node.linkedTermsAndConditions),
    [node.linkedTermsAndConditions],
  );
  const slots = useMemo(() => convertToSlots(node.subModuleContent, terms), [node.subModuleContent, terms]);
  const form = useMemo(() => convertToFormBlock(node.subModuleForm), [node.subModuleForm]);

  const variant = parseFormVariant(node.contentModuleFormVariants);
  const theme = parseVariantTheme(node.contentModuleVariantThemes);
  const size = parseVariantSize(node.contentModuleVariantSizes);
  if (!form) return <ContentModule variant="noImage" slots={slots} theme={theme} size={size} />; // Fallback
  return (
    <ContentModule
      variant={variant}
      slots={slots}
      form={form}
      image={node.image?.[0]}
      reverse={index % 2 === 0}
      theme={theme}
      size={size}
    />
  );
}
