import React, { Fragment, useMemo } from 'react';

import Repayments from '@/lib/Repayments';

import styles from './styles.module.scss';

interface Props {
  totalRepayment: number;
}

const AmountNumber = ({ totalRepayment }: Props) => {
  const amountValues = useMemo(() => {
    if (totalRepayment === 0) {
      return Array.from({ length: 6 }, (_, i) => 2000 * (i + 1));
    }
    const maxAmount = Repayments.getMaxAmountValueOnChart(totalRepayment);
    return Array.from({ length: 6 }, (_, i) => (maxAmount / 6) * (i + 1));
  }, [totalRepayment]);

  return (
    <>
      <div className={styles.amount} style={{ bottom: 0 }}>
        $0
      </div>
      {amountValues.map((value, idx) => (
        <Fragment key={`amount-${value}`}>
          <div className={styles.amount} style={{ bottom: `${((idx + 1) / 6) * 100}%` }}>
            ${value.toLocaleString()}
          </div>
          {idx !== 5 && <div className={styles.amountDivider} style={{ bottom: `${((idx + 1) / 6) * 100}%` }} />}
        </Fragment>
      ))}
    </>
  );
};

export default AmountNumber;
