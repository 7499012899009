import React, { useEffect, useMemo, useState } from 'react';
import cc from 'classcat';

import Image from '@/components/Image';
import { getIcon } from '@/lib/IconHelper';

import styles from './styles.module.scss';

interface Props {
  heading: string;
  items: Array<CarouselItem>;
  reserve: boolean;
}

export interface CarouselItem {
  paragraph: string;
  image?: Nullable<AssetFragment>;
  icon?: Nullable<IconFragment>;
}

export default function ContentCarousel({ heading, items, reserve }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeImage = useMemo(() => items[activeIndex]?.image, [activeIndex, items]);
  const verticallyCentredStyle = useMemo(() => items.length > 3, [items]);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % items.length);
    }, 7000);
    return () => {
      clearInterval(slideInterval);
    };
  }, [activeIndex, items.length]);

  return (
    <div
      className={cc({
        [styles.contentCarousel]: true,
        [styles.verticallyCentredStyle]: verticallyCentredStyle,
        [styles.reserve]: reserve,
      })}
    >
      <div className={styles.content}>
        <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }} />
        <div className={styles.items}>
          {items.map((item, index) => (
            <button
              className={cc({ [styles.slot]: true, [styles.active]: index === activeIndex })}
              onClick={() => setActiveIndex(index)}
              key={item.paragraph}
            >
              {item?.icon && getIcon(item.icon)}
              <div className={styles.item} dangerouslySetInnerHTML={{ __html: item.paragraph }} />
            </button>
          ))}
        </div>
      </div>
      <div className={styles.image}>
        <Image {...activeImage} />
      </div>
    </div>
  );
}
