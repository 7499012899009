import React from 'react';
import cc from 'classcat';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Select from '@/components/Dealerships/Index/components/DealershipsBar/DealershipBarSelect';
import useStore from '@/hooks/useStore';
import Repayments from '@/lib/Repayments';

import RepaymentChart from './Chart';
import FieldNumberInput from './RepaymentNumberInput';

import styles from './styles.module.scss';

interface Props {
  withAdditionButton?: boolean;
  ctaLabel?: Maybe<string>;
  children?: React.ReactNode;
}

const RepaymentForm = ({ ctaLabel = 'Show cars in this price range', withAdditionButton = false, children }: Props) => {
  const { finance } = useStore('globals');
  const initialValues: RepaymentFormValues = {
    loanAmount: 10000,
    interestRate: finance.interestRate || 6.95,
    loanLength: 5,
    frequency: 'week',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RepaymentForm.validationSchema}
      validateOnChange
      onSubmit={() => undefined}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const repayment = Repayments.getRepayment(
          values.loanAmount,
          values.loanLength,
          values.interestRate,
          finance.baseFees,
          Repayments.frequencyMap[values.frequency],
        );

        const applyUrl = `/cars?price=${Math.max(Math.min(999999, values.loanAmount), 10000)}`;

        return (
          <div className={styles.columns}>
            <RepaymentChart
              loanAmount={values.loanAmount}
              interestRate={values.interestRate}
              baseFees={finance.baseFees}
              loanLength={values.loanLength}
              frequency={values.frequency}
            />
            <div className={styles.form}>
              <form onSubmit={handleSubmit} className={styles.repaymentForm}>
                <fieldset>
                  <p>Enter your details</p>
                  <div className={styles.input}>
                    <label htmlFor="loanAmount">Loan amount</label>
                    <FieldNumberInput
                      defaultValue={values.loanAmount}
                      minValue={0}
                      maxValue={2000000}
                      onChange={(val: number) => setFieldValue('loanAmount', val)}
                      dollarIcon
                    />
                  </div>

                  <Select
                    formik
                    name="loanLength"
                    label="Loan term"
                    aria-label="Loan Length"
                    options={RepaymentForm.loanLengthOptions}
                    required
                  />
                  <Select
                    formik
                    name="frequency"
                    label="Repayment frequency"
                    aria-label="Repayment frequency"
                    options={RepaymentForm.frequencyOptions}
                    required
                  />
                  <div className={styles.input}>
                    <label htmlFor="loanAmount">Indicative interest rate</label>
                    <FieldNumberInput
                      defaultValue={values.interestRate}
                      minValue={0}
                      maxValue={100}
                      onChange={(val: number) => setFieldValue('interestRate', val)}
                      percentIcon
                      disabled
                    />
                  </div>
                </fieldset>
                {!!repayment && (
                  <>
                    <p className={styles.yourRepayment}>Your repayments would be</p>
                    <div
                      className={cc({
                        [styles.repayment]: true,
                        [styles.repaymentLarge]: repayment >= 10000,
                      })}
                    >
                      ${repayment ? Repayments.formatNumber(repayment) : '?'}&nbsp;
                      <span>per {values.frequency}</span>
                    </div>
                  </>
                )}
                <div className={styles.calculatorButtons}>
                  <Button type={withAdditionButton ? 'blue-transparent' : 'blue'} href={applyUrl}>
                    {ctaLabel}
                  </Button>
                  {children}
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

RepaymentForm.validationSchema = Yup.object().shape({
  loanAmount: Yup.number().min(10000).max(2000000),
  interestRate: Yup.number().min(0).max(100),
  loanLength: Yup.number().min(1).max(10),
  frequency: Yup.string(),
});

RepaymentForm.loanLengthOptions = [
  { title: '1 year', value: 1 },
  { title: '2 years', value: 2 },
  { title: '3 years', value: 3 },
  { title: '4 years', value: 4 },
  { title: '5 years', value: 5 },
  { title: '6 years', value: 6 },
  { title: '7 years', value: 7 },
];

RepaymentForm.frequencyOptions = [
  { title: 'Weekly', value: 'week' },
  { title: 'Fortnightly', value: 'fortnight' },
  { title: 'Monthly', value: 'month' },
  { title: 'Yearly', value: 'year' },
];

export default RepaymentForm;
