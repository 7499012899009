import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';

import DollarIcon from '@/images/icons/dollar-dark.svg';
import PercentIcon from '@/images/icons/percent.svg';

import styles from './styles.module.scss';

interface Props {
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number) => void;
  dollarIcon?: boolean;
  percentIcon?: boolean;
  disabled?: boolean;
}

const RepaymentNumberInput = ({
  defaultValue,
  minValue = 0,
  maxValue = 2000000,
  onChange,
  dollarIcon = false,
  percentIcon = false,
  disabled = false,
}: Props) => {
  const withValueLimit = (vals: NumberFormatValues) =>
    Number(vals.floatValue) <= maxValue && Number(vals.floatValue) >= minValue;

  const onValueChange = useCallback(
    (vals: NumberFormatValues) => {
      onChange(Number(vals.floatValue) || 0);
    },
    [onChange],
  );

  return (
    <div className={styles.field}>
      {dollarIcon && (
        <span className={styles.icon}>
          <DollarIcon />
        </span>
      )}
      <NumericFormat
        placeholder="0"
        thousandSeparator={true}
        defaultValue={defaultValue > 0 ? defaultValue : ''}
        onValueChange={onValueChange}
        isAllowed={withValueLimit}
        disabled={disabled}
      />
      {percentIcon && (
        <span className={styles.percentIcon}>
          <PercentIcon />
        </span>
      )}
    </div>
  );
};

export default RepaymentNumberInput;
