import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import { useEffectOnce } from 'react-use';
import { isNumber } from 'lodash';

import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import TermsAndConditionsBlock, { MAIN_TERMS_FRAGMENT } from '../TermsAndConditionsBlock';
import { InjectTermsAndConditionsOptions, injectTermsAndConditions } from './injectors';
import Module, { ModuleNode, prefetchModule } from './modules';

import styles from './styles.module.scss';

interface TermsAndConditionsOptions extends InjectTermsAndConditionsOptions {
  overrideOffset?: number;
  additionalContent?: JSX.Element;
}

interface Props {
  content: ContentBuilderFragment;
  termsAndConditionsOptions?: TermsAndConditionsOptions;
}

export default function ContentBuilder({ content, termsAndConditionsOptions }: Props) {
  const [nodes, allTerms] = useMemo(() => {
    const items = extractItems<ModuleNode>(content.nodes) || [];
    return injectTermsAndConditions(items, termsAndConditionsOptions);
  }, [content.nodes, termsAndConditionsOptions]);

  useEffectOnce(() => {
    const popStateEvent = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash === MAIN_TERMS_FRAGMENT || allTerms.some((term) => term.slug === hash)) {
        scroller.scrollTo(hash, {
          smooth: true,
          offset:
            termsAndConditionsOptions && isNumber(termsAndConditionsOptions?.overrideOffset)
              ? termsAndConditionsOptions.overrideOffset
              : -120, // header size + padding
        });
      }
    };

    window.addEventListener('popstate', popStateEvent);

    return () => {
      window.removeEventListener('popstate', popStateEvent);
    };
  });

  return (
    <div className={styles.content}>
      {nodes?.map((node, i) => (
        <Module node={node} key={`${node.__typename}-${i}`} index={i} />
      ))}
      {(!!allTerms.length || termsAndConditionsOptions?.additionalContent) && (
        <TermsAndConditionsBlock terms={allTerms}>
          {termsAndConditionsOptions?.additionalContent}
        </TermsAndConditionsBlock>
      )}
    </div>
  );
}

export async function prefetchContentBuilder(context: SSRContext, content: ContentBuilderFragment) {
  const nodes = extractItems<ModuleNode>(content.nodes) || [];
  await Promise.all(nodes.map((node) => prefetchModule(context, node)));
}
