import { Client } from 'urql';

import stockFiltersQuery from '@/graphql/queries/stockFilterHelper.graphql';
import Log from '@/lib/Log';

import { extractItems } from './GraphQLHelper';

export async function getMakeFilter(urqlClient: Client, filterString: string) {
  const { data, operation, error } = await urqlClient
    .query<StockFilterHelperQuery>(stockFiltersQuery, {
      filters: filterString,
      make: true,
    })
    .toPromise();

  if (error) {
    Log.error('getMakeFilter', { operation, error });
    return [];
  }

  return extractItems<MakeFilterOption>(data?.stockFilters?.make) ?? [];
}

export async function getBodyStyleFilter(urqlClient: Client, filterString: string) {
  const { data, operation, error } = await urqlClient
    .query<StockFilterHelperQuery>(stockFiltersQuery, {
      filters: filterString,
      body: true,
    })
    .toPromise();

  if (error) {
    Log.error('getBodyStyleFilter', { operation, error });
    return [];
  }

  return data?.stockFilters?.body?.filter((f): f is string => !!(f && typeof f === 'string')) ?? [];
}
