import { CarouselItem } from '@/components/ContentCarousel';
import { extractItems } from '@/lib/GraphQLHelper';

export type ChildNode = VizyHeadingFragment | VizyParagraphFragment | SubModuleCarouselItemFragment;

export default function covertToCarouselItems(node?: Nullable<SubModuleCarouselFragment>): Array<CarouselItem> {
  return (
    extractItems<ChildNode>(node?.nodes)
      ?.filter(
        (node): node is SubModuleCarouselItemFragment => node.__typename === 'subModuleCarousel_carouselItem_BlockType',
      )
      .map((item) => ({ paragraph: item.paragraph?.renderHtml || '', image: item.image?.[0], icon: item.icon })) || []
  );
}
