import React from 'react';
import { Element } from 'react-scroll';

import { ModifiedTermsAndConditionsEntryFragment } from '../VehicleDetailsPage/VDPTermsAndConditionsBlock';

import styles from './styles.module.scss';

interface Props {
  terms: Array<ModifiedTermsAndConditionsEntryFragment | TermsAndConditionsEntryFragment>;
  children?: React.ReactNode;
}

export const MAIN_TERMS_FRAGMENT = 'terms';

export default function TermsAndConditionsBlock({ terms, children }: Props) {
  return (
    <Element name={MAIN_TERMS_FRAGMENT}>
      <div className={styles.termsAndConditions}>
        <div className={styles.heading}>Terms and conditions</div>
        <div className={styles.items}>
          {terms.map((term, i) => (
            <React.Fragment key={`termsAndConditionsBlock-${term.slug}`}>
              <div className={styles.number}>[{i + 1}]</div>
              <div className={styles.text}>
                <Element name={term.slug!}>
                  <div className={styles.textHeading}>{term.title}</div>
                </Element>
                {React.isValidElement(term.body) ? (
                  term.body
                ) : (
                  <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: term.body || '' }} />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
        {children && <div className={styles.children}>{children}</div>}
      </div>
    </Element>
  );
}
