import React, { useMemo } from 'react';

import Repayments from '@/lib/Repayments';

import TotalInterest from '../totalInterest';
import AmountNumber from './amountNumber';
import Popups from './popup';
import Principal from './principal';
import RepaymentPoints from './repaymentPoints';
import YearNumber from './yearNumber';

import styles from './styles.module.scss';

interface Props {
  loanAmount: number;
  interestRate: number;
  baseFees: number;
  loanLength: number;
  frequency: keyof typeof Repayments.frequencyMap;
}

const RepaymentChart = ({ loanAmount, interestRate, baseFees, loanLength, frequency }: Props) => {
  const repayment = useMemo(() => {
    return Repayments.getRepayment(loanAmount, loanLength, interestRate, baseFees, Repayments.frequencyMap[frequency]);
  }, [loanAmount, interestRate, baseFees, loanLength, frequency]);

  const totalRepayment = useMemo(() => {
    return (repayment * loanLength * 52) / Repayments.frequencyMap[frequency];
  }, [repayment, loanLength, frequency]);

  const maxAmount = useMemo(() => {
    return Math.ceil(totalRepayment / 6000) * 6000 * 1.06;
  }, [totalRepayment]);

  return (
    <div className={styles.chart}>
      <TotalInterest
        loanAmount={loanAmount}
        interestRate={interestRate}
        baseFees={baseFees}
        loanLength={loanLength}
        frequency={frequency}
      />
      <div className={styles.chartContainer}>
        <YearNumber loanLength={loanLength} />

        <AmountNumber totalRepayment={totalRepayment} />

        {loanAmount > 0 && (
          <div className={styles.repaymentClipPath} style={{ height: `${(totalRepayment / maxAmount) * 100}%` }} />
        )}

        <Principal
          loanAmount={loanAmount}
          interestRate={interestRate}
          baseFees={baseFees}
          loanLength={loanLength}
          frequency={frequency}
          totalRepayment={totalRepayment}
        />

        <RepaymentPoints loanLength={loanLength} totalRepayment={totalRepayment} />

        {loanAmount > 0 && (
          <Popups
            loanAmount={loanAmount}
            interestRate={interestRate}
            baseFees={baseFees}
            loanLength={loanLength}
            frequency={frequency}
            totalRepayment={totalRepayment}
          />
        )}
      </div>
    </div>
  );
};

export default RepaymentChart;
