import { Slots } from '@/components/ContentModule';
import { SlotType } from '@/components/ContentModule/slots';
import { MAIN_TERMS_FRAGMENT } from '@/components/TermsAndConditionsBlock';
import { extractItems } from '@/lib/GraphQLHelper';

export type ChildNode =
  | VizyHeadingFragment
  | VizyParagraphFragment
  | VizyBulletListFragment
  | VizyOrderedListFragment
  | SubModuleContentButtonFragment
  | SubModuleContentDualButtonFragment
  | SubModuleContentDynamicButtonFragment;

export function convertToSlot(node: ChildNode): Nullable<SlotType> {
  switch (node.__typename) {
    case 'VizyNode_Heading':
      if (!node.html) return null;
      return Slots.Heading(node.html);
    case 'VizyNode_Paragraph':
      if (!node.html) return null;
      return Slots.Paragraph(node.html);
    case 'VizyNode_BulletList':
    case 'VizyNode_OrderedList':
      if (!node.html) return null;
      return Slots.List(node.html);
    case 'subModuleContent_button_BlockType':
      if (!node.customLinkField) return null;
      return Slots.Buttons({ link: node.customLinkField, icon: node.icon! });
    case 'subModuleContent_dualButton_BlockType':
      if (!node.customLinkField || !node.customLinkField2) return null;
      return Slots.Buttons(
        { link: node.customLinkField, icon: node.icon! },
        { link: node.customLinkField2, icon: node.icon! },
      );
    case 'subModuleContent_dynamicButton_BlockType':
      if (!node.contentModuleDynamicButtonVariants) return null;
      return Slots.DynamicButton(node);
    default:
      return null;
  }
}

export default function convertToSlots(
  node?: Nullable<SubModuleContentFragment>,
  terms?: Nullable<Array<TermsAndConditionsEntryFragment>>,
) {
  const nodes =
    extractItems<ChildNode>(node?.nodes)
      ?.map(convertToSlot)
      .filter((s): s is SlotType => !!s) || [];

  if (terms?.length) {
    const link = terms.length > 1 ? MAIN_TERMS_FRAGMENT : terms[0].slug!;
    nodes.push(Slots.Paragraph(`<a href="#${link}">*Terms & conditions apply</a>`));
  }

  return nodes;
}
