import mbxClient from '@mapbox/mapbox-sdk/lib/classes/mapi-client';
import mbxStatic from '@mapbox/mapbox-sdk/services/static';

import PublicEnvironment from '@/stores/PublicEnvironment';

import Log from './Log';

const StaticClient = () => {
  let r = null;
  try {
    const MapboxClient = new mbxClient({
      accessToken: PublicEnvironment.get('MAPBOX_TOKEN') || '',
    });
    r = mbxStatic(MapboxClient);
  } catch (e) {
    Log.error('Mapbox error', { e });
  }
  return r;
};

export { StaticClient };
