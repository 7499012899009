import React, { useMemo } from 'react';

import Repayments from '@/lib/Repayments';

import styles from './styles.module.scss';

interface Props {
  loanAmount: number;
  interestRate: number;
  baseFees: number;
  loanLength: number;
  frequency: keyof typeof Repayments.frequencyMap;
  totalRepayment: number;
}

const Principal = ({ loanAmount, interestRate, baseFees, loanLength, frequency, totalRepayment }: Props) => {
  const maxAmount = useMemo(() => {
    return Repayments.getMaxAmountValueOnChart(totalRepayment);
  }, [totalRepayment]);

  const principalValues = useMemo(() => {
    return Repayments.getPrincipalPerYear(
      loanAmount,
      loanLength,
      interestRate,
      baseFees,
      Repayments.frequencyMap[frequency],
    );
  }, [loanAmount, interestRate, baseFees, loanLength, frequency]);

  const principalClipPath = useMemo(() => {
    return Repayments.getClipPath(principalValues, loanAmount);
  }, [principalValues, loanAmount]);

  if (loanAmount === 0) {
    return null;
  }

  return (
    <>
      <div
        className={styles.principalClipPath}
        style={{
          height: `${(loanAmount / maxAmount) * 100}%`,
          clipPath: principalClipPath,
        }}
      />
      {principalValues.map((principal, idx) => (
        <div
          key={`principal-point-${idx}`}
          className={styles.principalPoint}
          style={{ left: `${((idx + 1) / loanLength) * 100}%`, bottom: `${(principal / maxAmount) * 100}%` }}
        />
      ))}
    </>
  );
};

export default Principal;
