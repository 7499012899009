import React, { useCallback, useMemo } from 'react';
import cc from 'classcat';

import { IconChevronLeft, IconChevronRight } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

type Props = {
  totalPages: number;
  currentPage: number;
  displayAmount: number;
  totalItems?: number;
  limit?: number;
  disabled?: boolean;
  itemType?: string;
  onPageChange: (pageNumber: number) => void;
};

const Pagination = React.memo(
  ({ totalPages, currentPage, displayAmount, totalItems, limit, disabled = false, itemType, onPageChange }: Props) => {
    const pageArr = useMemo(() => {
      let arr = Array.from({ length: totalPages }, (_, i) => i + 1);

      const max = currentPage + Math.floor(displayAmount / 2);
      if (max <= displayAmount) {
        return arr.slice(0, displayAmount);
      }
      if (max > totalPages) {
        return arr.slice(totalPages - displayAmount, totalPages);
      }

      arr = arr.slice(max - displayAmount, max < totalPages ? max : totalPages);
      return arr;
    }, [currentPage, totalPages, displayAmount]);

    const pageChange = useCallback(
      (page: number) => {
        onPageChange(page);
      },
      [onPageChange],
    );

    const handlePageChange = useCallback(
      (type: 'previous' | 'next' | 'first' | 'last') => {
        switch (type) {
          case 'previous':
            pageChange(currentPage - 1);
            break;
          case 'next':
            pageChange(currentPage + 1);
            break;
          case 'first':
            pageChange(1);
            break;
          case 'last':
            pageChange(totalPages);
            break;
        }
      },
      [pageChange, currentPage, totalPages],
    );

    if (currentPage > totalPages) {
      return null;
    }

    return (
      <div className={cc(styles.paginationWrapper)}>
        <div className={styles.pagination}>
          {currentPage !== 1 && (
            <button
              data-testid="pagination-button-lt"
              className={cc({ [styles.next]: true, [styles.disabled]: disabled })}
              onClick={() => handlePageChange('previous')}
              aria-label="Previous page"
            >
              <IconChevronLeft />
            </button>
          )}
          {!pageArr.includes(1) && (
            <button
              data-testid="pagination-button-first-page"
              className={cc({ [styles.active]: currentPage === 1 })}
              onClick={() => handlePageChange('first')}
            >
              1
            </button>
          )}
          {!pageArr.includes(2) && <span data-testid="pagination-button-first-range">...</span>}
          {pageArr.map((x) => (
            <button
              className={cc({ [styles.active]: x === currentPage, [styles.disabled]: x === currentPage })}
              data-testid="pagination-button-number"
              onClick={() => pageChange(x)}
              key={x}
            >
              {x}
            </button>
          ))}
          {!pageArr.includes(totalPages - 1) && !pageArr.includes(totalPages) && (
            <span data-testid="pagination-button-last-range">...</span>
          )}
          {!pageArr.includes(totalPages) && (
            <button
              data-testid="pagination-button-last-page"
              className={cc({ [styles.active]: currentPage === totalPages })}
              onClick={() => handlePageChange('last')}
            >
              {totalPages}
            </button>
          )}
          {currentPage !== totalPages && (
            <button
              data-testid="pagination-button-gt"
              className={cc({ [styles.next]: true, [styles.disabled]: disabled })}
              onClick={() => handlePageChange('next')}
              aria-label="Next page"
            >
              <IconChevronRight />
            </button>
          )}
        </div>
        {totalItems && limit && (
          <p data-testid="display-pages">
            Displaying {((currentPage - 1) * limit + 1).toLocaleString()}-
            {Math.min(currentPage * limit, totalItems).toLocaleString()} of {totalItems.toLocaleString()}
            {itemType ? ` ${itemType}` : ''}
          </p>
        )}
      </div>
    );
  },
);

Pagination.displayName = 'Pagination';

export default Pagination;

export function applyPagination<T>(items: Array<T>, currentPage: number, limit: number) {
  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / limit);

  const pagedItems = items.slice((currentPage - 1) * limit, currentPage * limit);
  return { pagedItems, totalItems, totalPages };
}
