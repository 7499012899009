import React, { useMemo } from 'react';
import cc from 'classcat';

import PageCard from '@/components/PageCard';
import { extractItems } from '@/lib/GraphQLHelper';

import styles from './styles.module.scss';

type EntryGridType = PageThumbnailFragment;

interface Props {
  node: ModuleEntryGridFragment;
}

export default function ModuleEntryGrid({ node }: Props) {
  const paragraph = node.paragraph?.renderHtml || '';
  const entries = useMemo(() => extractItems<EntryGridType>(node.entryGrid) || [], [node.entryGrid]);

  return (
    <div className={styles.entryGrid}>
      {paragraph && <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />}
      <div className={cc({ [styles.cards]: true, [styles.iconOnly]: node.toggle })}>
        {entries.map((entry) => renderEntryGrid(entry))}
      </div>
    </div>
  );
}

function renderEntryGrid(entry: EntryGridType) {
  switch (entry.__typename) {
    case 'pages_pages_Entry':
      return <PageCard key={entry.uid} entry={entry} />;
    // istanbul ignore next
    default:
      // Should never happen
      console.warn(`failed to render row item with typename ${entry['__typename']} - no render case exists`);
      // istanbul ignore next
      return null;
  }
}
