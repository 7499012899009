import React from 'react';

import ContentCarousel from '@/components/ContentCarousel';

import covertToCarouselItems from '../submodules/SubModuleCarousel';

interface Props {
  node: ModuleCarouselFragment;
}

export default function ModuleCarousel({ node }: Props) {
  const carouselItems = covertToCarouselItems(node.subModuleCarousel);
  return (
    <ContentCarousel heading={node.subModuleCarousel?.renderHtml || ''} items={carouselItems} reserve={!!node.toggle} />
  );
}
