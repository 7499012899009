import React from 'react';

import ContentCalculator from '@/components/ContentCalculator';

import { convertToButtons } from '../submodules/SubModuleCalculator';

interface Props {
  node: ModuleCalculatorFragment;
}

export default function ModuleCalculator({ node }: Props) {
  const additionalButtons = convertToButtons(node.subModuleCalculator);
  return <ContentCalculator ctaLabel={node.plainText ?? undefined} additionalButtons={additionalButtons} />;
}
