import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import Star from '@/images/icons/ancap-star.svg';
import EmptyStar from '@/images/icons/star--gold.svg';

import { TestimonialItem } from '..';

import styles from './styles.module.scss';

const MAX_LENGTH = 240;

export default function ContentTestimonialItem({ rating, name, paragraph }: TestimonialItem) {
  const isLong = paragraph.length >= MAX_LENGTH;
  return (
    <div className={styles.testimonial} data-testid="testimonial">
      {isLong ? (
        <ReadMoreText paragraph={paragraph} />
      ) : (
        <div className={styles.paragraph}>&ldquo;{paragraph}&ldquo;</div>
      )}
      <div className={styles.ratingWrapper}>
        <div className={styles.rating}>
          {[...Array(rating)].map((_, i) => (
            <Star key={i} />
          ))}
          {[...Array(5 - rating)].map((_, i) => (
            <EmptyStar key={i} />
          ))}
        </div>
        <div className={styles.name}>{name}</div>
      </div>
    </div>
  );
}

export function ReadMoreText({ paragraph }: Record<'paragraph', string>) {
  const [readMore, setReadMore] = useState(false);
  const aboveFold = paragraph.slice(0, MAX_LENGTH).trim();
  const blowFold = paragraph.slice(MAX_LENGTH, paragraph.length).trim();
  return (
    <div className={styles.paragraph}>
      {readMore ? (
        <>&ldquo;{aboveFold}</>
      ) : (
        <>
          &ldquo;{aboveFold}...&ldquo; <button onClick={() => setReadMore(true)}>read more</button>
        </>
      )}
      <AnimateHeight height={readMore ? 'auto' : 0}>
        {blowFold}&ldquo; <button onClick={() => setReadMore(false)}>show less</button>
      </AnimateHeight>
    </div>
  );
}
