import React from 'react';

import ContentTestimonial from '@/components/ContentTestimonial';

import covertToTestimonialItems from '../submodules/SubModuleTestimonial';

interface Props {
  node: ModuleTestimonialFragment;
}

export default function ModuleTestimonial({ node }: Props) {
  const items = covertToTestimonialItems(node.subModuleTestimonial);
  return <ContentTestimonial heading={node.subModuleTestimonial?.renderHtml || ''} items={items} />;
}
