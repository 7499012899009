import { FormBlockProps } from '@/components/ContentModule/common/FormBlock';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode = VizyHeadingFragment | VizyParagraphFragment | SubModuleFormButtonFragment;

export function convertToFormBlock(node?: Nullable<SubModuleFormFragment>): Nullable<FormBlockProps> {
  const children = extractItems<ChildNode>(node?.nodes);
  const heading = children?.find((node): node is VizyHeadingFragment => node.__typename === 'VizyNode_Heading')?.html;
  const paragraph = children?.find(
    (node): node is VizyParagraphFragment => node.__typename === 'VizyNode_Paragraph',
  )?.html;
  const button = children?.find(
    (node): node is SubModuleFormButtonFragment => node.__typename === 'subModuleForm_button_BlockType',
  )?.customLinkField;

  if (!heading || !button) return null;
  return {
    heading: heading,
    paragraph: paragraph || undefined,
    button: button,
  };
}
