import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Element, scroller } from 'react-scroll';
import { useLocation } from 'react-use';
import { useRouter } from 'next/router';

import GroupFilter from './GroupFilter';
import StaffList from './StaffList';

import styles from './styles.module.scss';

interface Props {
  staffs: StaffFragment[];
  groups: StaffGroupFragment[];
}

const ContentStaff = ({ staffs, groups }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [group, setGroup] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const router = useRouter();
  const location = useLocation();

  // Listen state change (search value on hero banner) to fire scrolling event
  useEffect(() => {
    if (location.trigger === 'pushstate') {
      scrollToContentSection();
    }
  }, [location]);

  // Listen search change to update staff list
  useEffect(() => {
    if (router.query.search) {
      setSearchValue(Array.isArray(router.query.search) ? router.query.search[0] : router.query.search);
    } else {
      setSearchValue('');
    }
    setCurrentPage(1);
  }, [router.query.search]);

  // Scroll to top off staff list section
  const scrollToContentSection = () => {
    setTimeout(() => {
      scroller.scrollTo('staffElement', {
        smooth: true,
        offset: -120,
      });
    }, 250);
  };

  // If search is active, do not apply role filter
  const list = useMemo(() => {
    if (searchValue) {
      return staffs.filter((s) => {
        const groupEntry = s.staffGroup?.[0];
        const group = !!(groupEntry && '__typename' in groupEntry) ? groupEntry.title! : '';
        return (
          s.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          s.plainText?.toLowerCase().includes(searchValue.toLowerCase()) ||
          group.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    if (group) {
      return staffs.filter((s) => {
        const staffGroup = s.staffGroup?.[0];
        return !!(staffGroup && '__typename' in staffGroup && staffGroup.title === group);
      });
    }
    return staffs;
  }, [group, searchValue, staffs]);

  const roleChange = (value: string) => {
    setGroup(value);
    setCurrentPage(1);
  };

  const setCurrentPageAndScroll = (page: number) => {
    setCurrentPage(page);
    setTimeout(() => {
      scrollToContentSection();
    }, 250);
  };

  return (
    <Element name="staffElement">
      <div className={styles.contentStaff}>
        {!searchValue && <GroupFilter groups={groups} currentGroup={group} onChange={roleChange} />}
        {list.length > 0 ? (
          <StaffList
            list={list}
            totalItems={list.length}
            currentPage={currentPage}
            entriesPerPage={ContentStaff.entriesPerPage}
            onPageChange={setCurrentPageAndScroll}
          />
        ) : (
          <div className={styles.notFound}>
            <p>No results matched your search</p>
          </div>
        )}
      </div>
    </Element>
  );
};

ContentStaff.entriesPerPage = 12;

export default observer(ContentStaff);
