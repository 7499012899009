import { ChildNode } from '@/components/ContentBuilder/modules/submodules/SubModuleCarousel';
import { MAIN_TERMS_FRAGMENT } from '@/components/TermsAndConditionsBlock';

import { extractItems } from './GraphQLHelper';

export const injectTerms = (html: string, allTermsMap: Map<string, number>, slug?: Nullable<string>) => {
  if (!html.includes('**')) return html;
  if (slug && allTermsMap.has(slug)) {
    return html.replace('**', `<a class="termsLink" href="#${slug}"><sup>[${allTermsMap.get(slug)!}]</sup></a>`);
  } else {
    return html.replace('**', `<a class="termsLink" href="#${MAIN_TERMS_FRAGMENT}"><sup>*</sup></a>`);
  }
};

export function injectTermsModuleCarousel(
  module: SubModuleCarouselFragment,
  terms: TermsAndConditionsEntryFragment[],
  allTermsMap: Map<string, number>,
) {
  let moduleTermsCount = 0;
  const injectModuleTerms = (html: Nullable<string> | undefined) => {
    if (!html?.includes('**')) return html;
    while (html.includes('**')) {
      html = injectTerms(html, allTermsMap, terms?.[moduleTermsCount++]?.slug);
    }
    return html;
  };

  const nodes = extractItems<ChildNode>(module.nodes)?.map((node) => {
    if (node.__typename === 'VizyNode_Paragraph') {
      return { ...node, html: injectModuleTerms(node.html) };
    }

    if (node.__typename === 'subModuleCarousel_carouselItem_BlockType' && node.paragraph) {
      return {
        ...node,
        paragraph: {
          ...node.paragraph,
          renderHtml: injectModuleTerms(node.paragraph.renderHtml),
        },
      };
    }
    return node;
  });

  return {
    ...module,
    nodes,
  } as SubModuleCarouselFragment;
}
