import React from 'react';

import RepaymentForm from '@/components/forms/RepaymentForm';

import CalculatorButton from './CalculatorButton';

import styles from './styles.module.scss';

interface Props {
  ctaLabel?: Maybe<string>;
  additionalButtons: Array<SubModuleCalculatorButtonFragment>;
}

const ContentCalculator = ({ ctaLabel, additionalButtons }: Props) => (
  <div id="calculateBlock" className={styles.calculator}>
    <div className={styles.container}>
      <div className={styles.content}>
        <RepaymentForm ctaLabel={ctaLabel} withAdditionButton={!!additionalButtons.length}>
          <React.Fragment>
            {additionalButtons.map(
              (button: SubModuleCalculatorButtonFragment, index: number) =>
                !!button.customLinkField && (
                  <CalculatorButton key={button.customLinkField.text} button={button.customLinkField} index={index} />
                ),
            )}
          </React.Fragment>
        </RepaymentForm>
      </div>
    </div>
  </div>
);

export default ContentCalculator;
