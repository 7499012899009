import React, { useMemo } from 'react';

import Repayments from '@/lib/Repayments';

import styles from './styles.module.scss';

interface Props {
  loanLength: number;
  totalRepayment: number;
}

const RepaymentPoints = ({ loanLength, totalRepayment }: Props) => {
  const repaymentArray = useMemo(() => {
    if (totalRepayment === 0) {
      return [];
    }

    const maxAmount = Repayments.getMaxAmountValueOnChart(totalRepayment);
    const repaymentPercentMax = (totalRepayment / maxAmount) * 100;
    const values: number[] = [];
    for (let i = 1; i <= loanLength - 1; i++) {
      values.push(repaymentPercentMax - (repaymentPercentMax / loanLength) * i);
    }
    return values;
  }, [totalRepayment, loanLength]);

  return (
    <>
      {repaymentArray.map((repayment, idx) => (
        <div
          key={`repayment-point-${idx}`}
          className={styles.repaymentPoint}
          style={{ left: `${((idx + 1) / loanLength) * 100}%`, bottom: `${repayment}%` }}
        />
      ))}
    </>
  );
};

export default RepaymentPoints;
