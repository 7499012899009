import React, { useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';

import Chevron from '@/images/icons/chevron--down.svg';

import { FaqItem } from '../';

import styles from './styles.module.scss';

export default function ContentFaqItem({ heading, paragraph }: FaqItem) {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.contentFaqItem}>
      <button onClick={() => setOpen(!open)} className={cc({ [styles.top]: true, [styles.open]: open })}>
        <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }} />
        <div className={styles.chevron}>
          <Chevron />
        </div>
      </button>
      <AnimateHeight height={open ? 'auto' : 0}>
        <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />
      </AnimateHeight>
    </div>
  );
}
