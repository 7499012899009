import React from 'react';

import Button from '@/components/Button';
import ImageOrPlaceholder from '@/components/ContentModule/common/ImageOrPlaceholder';

import styles from './styles.module.scss';

type Props = {
  entry: PageThumbnailFragment;
};

const PageCard = ({ entry }: Props) => {
  const thumbnail = entry.thumbnail?.[0];

  return (
    <div className={styles.boatCard} data-testid="pageCard">
      <div className={styles.imageContainer}>
        <a href={`/${entry.uri}`} aria-label={entry.title!}>
          <ImageOrPlaceholder image={thumbnail} className={styles.image} />
        </a>
      </div>
      <div className={styles.content}>
        <h3>{entry.title}</h3>
        <p>{entry.description}</p>
        <div className={styles.buttons}>
          <Button href={`/${entry.uri}`} type="action" size="medium">
            See more <span className={styles.screenReaderText}>details</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageCard;
