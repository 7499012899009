import React from 'react';

import Button from '@/components/Button';

interface Props {
  button: CustomLinkFieldFragment;
  index: number;
}

export default function CalculatorButton({ button, index }: Props) {
  return (
    <Button href={button.url!} target={button.target || undefined} type={index % 2 ? 'blue-transparent' : 'blue'}>
      {button.text}
    </Button>
  );
}
