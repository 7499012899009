import React, { useEffect, useMemo, useState } from 'react';
import cc from 'classcat';

import Button from '@/components/Button';
import ImageOrPlaceholder from '@/components/ContentModule/common/ImageOrPlaceholder';
import { Dialog, DialogContent } from '@/components/Dialog';
import useIsMobile from '@/hooks/useIsMobile';
import { IconId, IconMail, IconMessageLanguage, IconPhone, IconX } from '@/images/icons/tabler-icons';
import { telPhone } from '@/lib/utils';

import styles from './styles.module.scss';

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  entry: StaffFragment;
}

const StaffModal = ({ open, setOpen, entry }: Props) => {
  const [isMobile] = useIsMobile(768);
  const [smsContent, setSmsContent] = useState('');

  const message = useMemo(() => {
    return `Place holder message content: ${entry.title}`;
  }, [entry]);

  const iOS = !!window.navigator.userAgent?.match(/(iPhone|iPad|iPod)/);

  useEffect(() => {
    if (entry.phone) {
      if (iOS) {
        setSmsContent(`sms://${entry.phone}/&body=${encodeURIComponent(message)}`);
      } else {
        setSmsContent(`sms://${entry.phone}/?body=${encodeURIComponent(message)}`);
      }
    }
  }, [message, entry.phone, setSmsContent, iOS]);

  return (
    <Dialog open={open} onOpenChange={() => setOpen(false)} size={isMobile ? 'fullscreen' : 'large'}>
      <DialogContent>
        <div className={styles.dialogContent}>
          <button className={styles.closeButton} type="button" onClick={() => setOpen(false)} data-testid="closeButton">
            <IconX />
          </button>
          <div className={styles.content}>
            <div className={styles.imageContainer}>
              <ImageOrPlaceholder image={entry.image?.[0]} className={styles.image} />
            </div>
            <h1>{entry.title}</h1>
            {!!entry.plainText && (
              <div className={styles.groups}>
                <div className={styles.title}>{entry.plainText}</div>
              </div>
            )}
            {entry.body && <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: entry.body }} />}
            <div className={styles.info}>
              {!!entry.email && (
                <a href={`mailto:${entry.email}`} className={styles.contact}>
                  <IconMail />
                  <span>{entry.email}</span>
                </a>
              )}
              {!!entry.phone && (
                <a href={`tel:${telPhone(entry.phone!)}`} className={styles.contact}>
                  <IconPhone />
                  <span>{entry.phone}</span>
                </a>
              )}
              {entry.languages && (
                <div className={styles.contact}>
                  <IconMessageLanguage />
                  {entry.languages}
                </div>
              )}
            </div>

            <div className={styles.contactButtons} data-hide-only="mobile">
              {!!entry.email && (
                <Button size="small" href={`mailto:${entry.email}`} type="action">
                  <IconMail />
                  Email
                </Button>
              )}
              {!!entry.phone && (
                <Button size="small" href={`tel:${telPhone(entry.phone!)}`} type="action-transparent">
                  <IconPhone />
                  Call
                </Button>
              )}
            </div>
            {!!entry.phone && (
              <div className={styles.contactButtons} data-show-only="mobile">
                <Button size="small" href={`tel:${telPhone(entry.phone!)}`} type="action">
                  <IconMail />
                  Call
                </Button>
                <Button size="small" href={smsContent} type="action-transparent">
                  <IconPhone />
                  Message
                </Button>
              </div>
            )}
            <div className={cc({ [styles.vcardLink]: true, [styles.noPhone]: !entry.phone })}>
              <Button
                size="small"
                href={`/api/vcard/generate?slug=${entry.slug!}`}
                type="link"
                styleOverride={styles.vcard}
              >
                <IconId />
                Download vCard
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StaffModal;
